import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Role } from '../_model/user';
import { AuthService } from './AuthService';
import { SessionstorageService } from './SessionstorageService';
import { GlobalService } from './GlobalService';

@Injectable()
export class AuthGuardService  {

  constructor(
    private authService: AuthService,
    private router: Router,
    private _sessionStorage: SessionstorageService,
    private _globalService: GlobalService,

  ) {
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    //console.log(state.url)
    if (route.url[0] && route.url[0].path === 'personalmenu') {
      //if user not is authenticated or in the localstorage are still steps found, the user can continue with the personalmenu onboarding
      const orderflowsubscriptionform = this._sessionStorage.getObject('orderflowsubscriptionform')
      const subscriptionorderflowlines = this._sessionStorage.getObject('subscriptionorderflowlines')
      if (!this.authService.isAuthenticated())  {
        return true;
      } else if (
        this.authService.isAuthenticatedSubject.value && orderflowsubscriptionform && (subscriptionorderflowlines && subscriptionorderflowlines.length > 0)
        || this.authService.isAuthenticatedSubject.value && this._globalService.getPreviousUrl().startsWith('/user')) {
        return true
      } else {
        this.router.navigate(['user']);
        return false
      }

    } 
    if (route.url[0] && route.url[0].path === 'telemarketing') {
      //check if the telemarketing people can goto the telemarketing page
      if (this.authService.isAuthenticated() && this.authService.hasRole(Role.TELEMARKETING)) {
        return true;
      } else if (!this.authService.isAuthenticated()) {
        if (state.url) {
          const reg = RegExp(/returnUrl=.*/gm)
          const url = state.url.toString().replace(reg, "");
          this.router.navigate(['login'], { queryParams: { returnUrl: url } });
        }
        return false;
      } else {
        this.router.navigate(['']);
      }
    }

    //catchall for all routes that have a canActivate Authguard
    if (!this.authService.isAuthenticated()) {
      if (state.url) {
        const reg = RegExp(/returnUrl=.*/gm)
        const url = state.url.toString().replace(reg, "");
        this.router.navigate(['login'], { queryParams: { returnUrl: url } });
      }
      return false;
      //always navigates the telemarketing users to the telemarketing page, they shouldnt do anything else
    } else if (
      !state.url.startsWith('/telemarketing') && this.authService.hasRole(Role.TELEMARKETING)
    ) {
      this.router.navigate(['telemarketing']);
      return false;
    }

    return true;
  }

  

  

}
